import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import styled from 'styled-components';

import * as API from '../apis';
import { LABEL } from '../constants/ga';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Video from '../components/Video';

const Section = styled.section`
  border-top: solid ${({ top }) => (top ? '0' : '1px')} #eceff0;
  margin-bottom: 50px;
`;

const SectionTitle = styled.h3`
  margin: 1.25rem 0;
  font-weight: 600;
  color: #10abe2;

  ${({ top }) => top && `margin-top: 0`}
`;

const VideoList = styled.div`
  display: grid;
  grid-gap: 1.875rem;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  margin-bottom: 1.875rem;
`;

function IndexPage({ data }) {
  const { unPublishedNodes } = data;
  const unPublishedVideos = unPublishedNodes.edges.map(({ node }) => node);
  const [displayUnpublished, setDisplayUnpublished] = useState(false);

  useEffect(() => {
    (async () => {
      const response = await API.getUserProfile();
      setDisplayUnpublished(response.isAdmin);
    })();
  }, []);

  return (
    <Layout noTopPadding>
      <SEO title="Trang chủ" />

      {displayUnpublished && (
        <Section top>
          <SectionTitle top>CHƯA phát hành (chỉ admin mới thấy được)</SectionTitle>
          <VideoList>
            {unPublishedVideos.map(video => (
              <Video
                key={video.slug}
                title={video.name}
                subtitle={video.subject && video.subject.name}
                thumbnail={video.thumbnail}
                link={`videos/${video.slug}`}
                duration={video.duration}
                viewCount={null}
                publishDate={video.publishDate}
                type={video.contentType}
                gaLabel={LABEL.HOME_PAGE_NEW_RELEASES}
              />
            ))}
          </VideoList>
        </Section>
      )}
    </Layout>
  );
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    unPublishedNodes: PropTypes.shape({
      edges: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }).isRequired,
};

export default IndexPage;

export const pageQuery = graphql`
  query {
    unPublishedNodes: allTrangVideo(
      filter: { isUnpublished: { eq: true } }
      sort: { order: DESC, fields: publishDate }
    ) {
      edges {
        node {
          ...VideoFragment
        }
      }
    }
  }
`;
